import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import posed, { PoseGroup } from 'react-pose'
import KnowledgeBlock from '../../molecules/KnowledgeBlock'

const Container = posed.div({
  enter: { staggerChildren: 200 },
  exit: { staggerChildren: 50 },
})

const H1 = posed.h1({
  enter: { y: 0, opacity: 1 },
  exit: { y: -50, opacity: 0 },
})

class Knowledge extends Component {
  state = { isVisible: false };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isVisible: !this.state.isVisible
      });
    }, 2500);
  }

  render() {
    const { isVisible } = this.state;
    const props = this.props;

    return(
      <StaticQuery
        query={graphql`
          query KnowledgeSection {
            homeKnowledgeJson{
              knowledge{
                title
                blocks{
                  title
                  template
                  content{
                    value
                  }
                }
              }
            }
          }
        `}
        render={ data => {
          return (
            <PoseGroup>
              {isVisible
                ? <Container key="knowledgeBlocks" className="px-10 pt-16 md:p-20 lg:px-32">
                    <H1 className="text-white pb-10 font-mono text-2xl lg:text-4xl">{data.homeKnowledgeJson.knowledge.title}</H1>
                    <div className={`flex flex-wrap flex-col lg:flex-row`}>
                      {printBlocks({data, props})}
                    </div>
                  </Container>
                : null
              }
            </PoseGroup>
          )}
        }
      />
    )
  }
}

const printBlocks = ({data}) => {
  const blocks = data.homeKnowledgeJson.knowledge.blocks

  const renderedBlocks = blocks.map((block, i) =>(
    <KnowledgeBlock
      key={i}
      template={block.template || 1}
      title={`${block.title}`}
      description={block.description}
      list={block.content}
    />
  ))

  return renderedBlocks
}

export default Knowledge;
