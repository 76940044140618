import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components'

const Container = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 50, opacity: 0 },
})

const Ul = styled.ul`
  list-style: square inside;
  text-transform: uppercase;
`

const KnowledgeBlock = props => {
  const { template, list } = props

  return(
    <Container className={"flex-basis-1/4 pb-8 lg:px-5"}>
      <h3 className="text-white font-mono pb-2 lg:pb-4 text-lg lg:text-2xl">{props.title}</h3>
      {printList(props.list)}
    </Container>
  )
}

const printList = (list) => (
  <Ul className="text-white font-mono text-sm">
    {list.map((el, i) =>(
      <li className="text-sm px-4" key={i}>{el.value}</li>
    ))}
  </Ul>
)

export default KnowledgeBlock
